<template>
  <div>
    <!-- <HeaderComp1 /> -->
    <section id="careers-development-page" class="content-height">
      <PageLoader />
      <!--<InternalNavbar />-->
      <div class="careers-information">
        <div class="hr"></div>
        <div class="container">
          <div class="titleS">
            <span>{{ $t("title.career_development") }}</span>
          </div>
          <div class="content">
            <img
              class="img-fluid mb-30"
              :src="
              'http://etf-admin.ispdemos.com/storage/app/public/' + row.img"
              :alt="row[$i18n.locale+'_title']"
              :title="row[$i18n.locale+'_title']"
            />
            <div class="pragraph">
              <p>{{ row[$i18n.locale+'_desc'] }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PageLoader from "@/components/PageLoader.vue";
// import HeaderComp1 from "@/components/HeaderComp1.vue";
//import InternalNavbar from "@/components/InternalNavbar.vue";

export default {
  components: {
    PageLoader,
    //HeaderComp1,
  //InternalNavbar
  },
  data() {
    return {
      
      row: []
    };
  },
  methods: {},
  mounted() {
    this.loading(7000);
    this.$http
      .get("pagesBy/2")
      .then(response => {
        this.row = response.data.data;
      })
      .catch(error => console.log(error))
      .finally(() => (this.loading = false));
  }
};
</script>