<template>
  <div>
    <!-- <HeaderComp1 /> -->
    <section id="doing-business-page" class="content-height">
      <PageLoader />
      <!--<InternalNavbar />-->
      <div class="hr"></div>
      <div class="container-fluid">
        <div class="titleS">
          <span>{{ $t("title.doing_business") }}</span>
        </div>
          <div v-if=" (result.data && result.data.length ? result.data.length : 0) == 0">
              <div id="loading_c"></div>
              <h2 id="not_found" style="color:black;">{{ $t("not_found") }}</h2>
          </div>
        <div class="content text-right" dir="rtl" v-if=" (result.data && result.data.length ? result.data.length : 0) != 0">
          <!-- Nav tabs -->
          <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item" v-for="(r, i) in result.data" :key="r.en_slug + i">
              <a
                :class="[i == 0 ? 'active' : ''] + ' nav-link'"
                data-toggle="tab"
                :href="'#menu' + i"
                >{{ r[$i18n.locale + "_title"] }}</a
              >
            </li>
          </ul>
          <div class="tab-content">
            <template v-for="(r, x) in result.data">
              <div
                :id="'menu' + x"
                :key="r.en_slug + x"
                :class="[x == 0 ? 'active' : ''] + ' tab-pane'"
              >
                <div v-if="(r.businesses && r.businesses.length ? r.businesses.length : 0) == 0">
                  <h2>لا يوجد قوانين</h2>
                </div>
                <div v-for="(r2, y) in r.businesses" :key="r2.en_slug + x">
                  <h3>{{ r2.ar_title }}</h3>
                  <p>
                    {{ getPragraphLimit(r2.ar_desc, 180) }}
                    <a
                      href="#"
                      data-toggle="modal"
                      :data-target="'#exampleModal' + y"
                      >المزيد</a
                    >
                  </p>
                  <div
                    class="modal fade"
                    :id="'exampleModal' + y"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="exampleModalLabel">
                            {{ r2.ar_title }}
                          </h5>
                        </div>
                        <div class="modal-body">{{ r2.ar_desc }}</div>
                        <div class="modal-footer">
                          <button
                            type="button"
                            class="btn btn-secondary"
                            data-dismiss="modal"
                          >
                            إغلاق
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <nav aria-label="Page navigation example">
          <pagination
            :align="'center'"
            :data="result"
            @pagination-change-page="get_result"
          >
            <span slot="prev-nav" v-html="$t('pagination.previous')"></span>
            <span slot="next-nav" v-html="$t('pagination.next')"></span>
          </pagination>
        </nav>
      </div>
    </section>
  </div>
</template>

<script>
import PageLoader from "@/components/PageLoader.vue";
// import HeaderComp1 from "@/components/HeaderComp1.vue";
// import InternalNavbar from "@/components/InternalNavbar.vue";
export default {
  components: {
    PageLoader
    //HeaderComp1,
    // InternalNavbar,
  },
  data: function() {
    return {
      result: { total: 0 }
    };
  },
  methods: {
    get_result: function(page = 1) {
      var vm = this;
      this.$http.get("doing?count=10&page=" + page).then(response => {
        vm.result = response.data;
      });
      this.$emit("updateHead");
      this.loaded = false;
    }
  },
  mounted: function() {
    this.get_result();
    this.loading(7000);
  }
};
</script>
