<template>
  <div>
    <!-- <HeaderComp1 /> -->
    <section id="members-internal-page" class="content-height">
      <!--<InternalNavbar />-->
      <PageLoader />
      <div class="hr"></div>
      <div class="container">
        <div class="titleS">
          <span>{{ row[$i18n.locale + "_title"] }}</span>
        </div>
        <div class="content">
          <!-- <div class="tittle">
            <img class="img-fluid" src="../../assets/images/tittle.png" alt />
            <h2>{{row[$i18n.locale+'_title']}}</h2>
          </div>-->
          <p>{{ row[$i18n.locale + "_desc"] }}</p>
        </div>
        <div class="sec-2">
          <div class="row justify-content-center">
            <div
              class="col-xl-4 col-lg-6 col-md-4"
              v-for="(r, i) in row.members"
              :key="i"
            >
              <div class="content">
                <div class="row">
                  <div class="col-lg-6">
                    <!-- <img
                      :title="r[$i18n.locale + '_title']"
                      class="img-fluid"
                      :src="
                        'http://etf-admin.ispdemos.com/storage/app/public/' +
                          r.img
                      "
                      :alt="r[$i18n.locale + '_title']"
                    /> -->
                    <div class="img" :style="{ backgroundImage: ` url(http://etf-admin.ispdemos.com/storage/app/public/${r.img})` }">

                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="info">
                      <li>{{ r[$i18n.locale + "_name"] }}</li>
                      <li>{{ r[$i18n.locale + "_group_name"] }}</li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if=" (row.members && row.members.length ? row.members.length : 0) == 0">
                <div id="loading_c"></div>
              <h2 id="not_found" style="color:black;">{{ $t("not_found") }}</h2>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PageLoader from "@/components/PageLoader.vue";
// import HeaderComp1 from "@/components/HeaderComp1.vue";
//import InternalNavbar from "@/components/InternalNavbar.vue";
export default {
  components: {
    PageLoader,
    //HeaderComp1,
  //InternalNavbar
  },
  data: function() {
    return {
      
      row: []
    };
  },
  methods: {},
  mounted() {
    
    this.$http
      .get("group/" + this.$route.params.id)
      .then(response => {
        this.row = response.data.data;
        if (Object.keys(this.row).length == 1) {
          this.$router.push("/404");
        }
      })
  }
};
</script>
