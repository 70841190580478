<template>
  <div>
    <!-- <HeaderComp1 /> -->
    <section id="board-members-page" class="content-height">
      <!--<InternalNavbar />-->
      <PageLoader />
      <div class="hr"></div>
      <div class="container">
        <div class="titleS">
          <span>{{ $t("title.board_members_m") }}</span>
        </div>
        <div class="content">
          <div class="row justify-content-center bootstrap">
            <div class="col-md-4">
              <div class="card">
                <img
                  :title="row1[$i18n.locale+'_title']"
                  class="img-fluid"
                  :src="'http://etf-admin.ispdemos.com/storage/app/public/' + row1.img"
                  :alt="row1[$i18n.locale + '_title']"
                />
                <!-- <span class="date">1 Jan 2020</span> -->
                <router-link to="/board-members-management/etf-staff" tag="a">
                  <!-- {{ $t("title.etf_staff") }} -->
                  {{ row1[$i18n.locale + '_title'] }}
                </router-link>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card">
                <img
                  :title="row2[$i18n.locale+'_title']"
                  class="img-fluid"
                  :src="'http://etf-admin.ispdemos.com/storage/app/public/' + row2.img"
                  :alt="row2[$i18n.locale + '_title']"
                />
                <!-- <span class="date">1 Jan 2020</span> -->
                 <router-link to="/board-members-management/board-members" tag="a">
                  <!-- {{ $t("title.board_members") }} -->
                  {{ row2[$i18n.locale + '_title'] }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PageLoader from "@/components/PageLoader.vue";
// import HeaderComp1 from "@/components/HeaderComp1.vue";
//import InternalNavbar from "@/components/InternalNavbar.vue";
export default {
  components: {
    PageLoader,
    //HeaderComp1,
  //InternalNavbar
  },
  data: function() {
    return {
      
      row1: [],
      row2: []
    };
  },
  methods: {
    getDanger() {
      
      this.$http
        .get("boardsBy/2")
        .then(response => {
          this.row1 = response.data.data;
        })
        .catch(error => console.log(error));
    },
    getSuccess() {
      
      this.$http
        .get("boardsBy/1")
        .then(response => {
          this.row2 = response.data.data;
        })
        .catch(error => console.log(error));
    }
  },
  mounted() {
    this.getDanger();
    this.getSuccess();
    // this.loading(7000);
  }
};
</script>