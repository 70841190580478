<template>
  <div>
    <!-- <HeaderComp1 /> -->
    <section id="news-page" class="content-height">
      <PageLoader />
      <!--<InternalNavbar />-->
      <div id="news-internal">
        <div class="hr"></div>
        <div class="container">
          <div class="titleS">
            <span>{{ row[$i18n.locale + "_title"] }}</span>
          </div>
          <div class="content">
            <div class="news">
              <h2>{{ row[$i18n.locale + "_title"] }}</h2>
              <carousel-3d
                :perspective="180"
                :controls-visible="true"
                :width="600"
                :display="1"
                :count="(row.conditionable && row.conditionable.length ? row.conditionable.length : 0)"
                v-if="(row.conditionable && row.conditionable.length ? row.conditionable.length : 0)"
              >
                <slide v-for="(r, i) in row.conditionable" :index="i" :key="i">
                  <img
                    :title="r[$i18n.locale + '_title']"
                    class="img-fluid"
                    :src="
                      'http://etf-admin.ispdemos.com/storage/app/public/' +
                        r.img
                    "
                    :alt="r[$i18n.locale + '_title']"
                  />
                </slide>
              </carousel-3d>
              <!-- <swiper class="swiper" :options="swiperOption1">
                <swiper-slide v-for="(r, i) in row.conditionable" :key="r.en_title+i">
                  <img
                    :title="r[$i18n.locale+'_title']"
                    class="img-fluid img-slider-w100"
                    :src="'http://etf-admin.ispdemos.com/storage/app/public/' + r.img"
                    :alt="r[$i18n.locale+'_title']"
                  />
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
              </swiper>-->
              <div v-html="row[$i18n.locale + '_desc']"></div>
              <span class="date">{{ row.date | moment("DD MMM YYYY") }}</span>
            </div>
          </div>
        </div>
        <div class="more-news" v-if="(result && result.length ? result.length : 0) > 1">
          <h3>{{ $t("more_news") }}</h3>
          <div class="container">
            <!-- <carousel-3d
              :disable3d="true"
              :space="425"
              :clickable="false"
              :border="0"
              :controls-visible="true"
              :count="result.length"
            >
              <slide v-for="(r, i) in result" :key="r.i" :index="(i)" v-show="r.id != row.id">
                <router-link :to="'/news-room/' + r.en_slug" tag="a">
                  <img
                    :title="r[$i18n.locale+'_title']"
                    class="img-fluid"
                    :src="'http://etf-admin.ispdemos.com/storage/app/public/' + r.img"
                    :alt="r[$i18n.locale+'_title']"
                  />
                  <h3>{{r[$i18n.locale+'_title']}}</h3>
                </router-link>
              </slide>
            </carousel-3d>-->
            <swiper class="swiper" :options="swiperOption2">
              <swiper-slide
                v-for="(r, i) in result"
                :key="r.en_slug + i"
                v-show="r.id != row.id"
              >
                <router-link @click.native="scrollToTop" :to="'/news-room/' + r.en_slug" tag="a">
                  <!-- <img
                    :title="r[$i18n.locale + '_title']"
                    class="img-fluid img-more-news"
                    :src="
                      'http://etf-admin.ispdemos.com/storage/app/public/' +
                        r.img
                    "
                    :alt="r[$i18n.locale + '_title']"
                  /> -->
                  <div class="img" :style="{ backgroundImage: ` url(http://etf-admin.ispdemos.com/storage/app/public/${r.img})` }"></div>
                  <h5 class="text-center mt-20">
                    {{ r[$i18n.locale + "_title"] }}
                  </h5>
                </router-link>
              </swiper-slide>
              <div class="swiper-button-prev" slot="button-prev"></div>
              <div class="swiper-button-next" slot="button-next"></div>
            </swiper>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PageLoader from "@/components/PageLoader.vue";
// import HeaderComp1 from "@/components/HeaderComp1.vue";
//import InternalNavbar from "@/components/InternalNavbar.vue";
// import { Carousel3d, Slide } from "vue-carousel-3d";

export default {
  data() {
    return {
      // slides: 5,
      row: [],
      result: []
    };
  },
  components: {
    PageLoader,
    //HeaderComp1,
  //InternalNavbar
    // Carousel3d,
    // Slide
  },
  computed: {
    getFullPath() {
      return this.$route.path;
    }
  },
  watch: {
    getFullPath() {
      this.get_row();
      this.get_result();
    }
  },
  mounted() {
    this.get_result();
    this.get_row();
  },
  methods: {
    get_result: function() {
      var vm = this;
      this.$http.get("more_news").then(response => {
        vm.result = response.data.data;
      });
      this.$emit("updateHead");
    },
    get_row: function() {
      
      this.$http
        .get("news/" + this.$route.params.id)
        .then(response => {
          this.row = response.data.data;
          if (Object.keys(this.row).length == 1) {
            this.$router.push("/404");
          }
        })
        .catch(error => console.log(error));
    },
    scrollToTop() {
        window.scrollTo(0,0);
      }
  },

  created: function() {}
};
</script>
