<template>
  <div>
    <!-- <HeaderComp1 /> -->
    <section id="sub-project" class="content-height">
      <!--<InternalNavbar />-->
      <PageLoader />
      <div class="hr"></div>
      <div class="container">
        <div class="titleS">
          <span>{{ row[$i18n.locale + "_title"] }}</span>
        </div>
        <div class="content">
          <img
            class="img-fluid"
            :src="'http://etf-admin.ispdemos.com/storage/app/public/' + row.img"
            alt
          />

          <div class="p">
            <div v-html="row[$i18n.locale + '_desc']"></div>
          </div>
          <div class="hyper-link" v-if="row.link">
            <a :href="row.link" target="_blank">{{ row.link }}</a>
          </div>
        </div>
        <div class="carousel-3d" v-if="(row.conditionable && row.conditionable.length ? row.conditionable.length : 0) != 0">
          <h2>{{ $t("project_photos") }}</h2>
          <carousel-3d
            :controls-visible="true"
            :controls-width="30"
            :controls-height="60"
            :border="0"
            :clickable="false"
            :count="row.conditionable.length"
          >
            <slide v-for="(r, i) in row.conditionable" :key="i" :index="i">
              <img
                :title="r[$i18n.locale + '_title']"
                class="img-fluid"
                :src="
                  'http://etf-admin.ispdemos.com/storage/app/public/' + r.img
                "
                :alt="r[$i18n.locale + '_title']"
              />
            </slide>
          </carousel-3d>
          <!-- <div class="example-3d">
            <swiper class="swiper" :options="swiperOption3">
              <swiper-slide v-for="(r , i ) in row.conditionable" :key="r.en_title+i">
                <img
                :title="r[$i18n.locale+'_title']"
                class="img-fluid"
                :src="'http://etf-admin.ispdemos.com/storage/app/public/' + r.img"
                :alt="r[$i18n.locale+'_title']"
              />
              </swiper-slide>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
            </swiper>
          </div> -->
        </div>
        <div v-else>
          <br>
          <br>
          <br>
        </div>
      </div>
      <div class="more-projects">
        <h3>{{ $t("more_projects") }}</h3>
        <div class="container">
          <!-- <carousel-3d
              :disable3d="true"
              :space="425"
              :clickable="false"
              :border="0"
              :controls-visible="true"
            >
              <slide v-for="(r, i) in result" :key="r.i" :index="(i-1)">
                <router-link :to="'projects/' + r.en_slug" tag="a">
                  <img
                    :title="r[$i18n.locale+'_title']"
                    class="img-fluid"
                    :src="'http://etf-admin.ispdemos.com/storage/app/public/' + r.img"
                    :alt="r[$i18n.locale+'_title']"
                  />
                  <h3>{{r[$i18n.locale+'_title']}}</h3>
                </router-link>
              </slide>
          </carousel-3d>-->
          <swiper class="swiper" :options="swiperOption2">
            <swiper-slide
              v-for="(r, i) in result"
              :key="r.en_slug + i"
              v-show="r.id != row.id"
            >
              <router-link @click.native="scrollToTop" :to="'/projects/' + r.en_slug" tag="a">
                <!-- <img
                  :title="r[$i18n.locale + '_title']"
                  class="img-fluid img-more-news"
                  :src="
                    'http://etf-admin.ispdemos.com/storage/app/public/' + r.img
                  "
                  :alt="r[$i18n.locale + '_title']"
                /> -->
                <div class="img" :style="{ backgroundImage: ` url(http://etf-admin.ispdemos.com/storage/app/public/${r.img})` }"></div>
                <h5 class="text-center mt-20">
                  {{ r[$i18n.locale + "_title"] }}
                </h5>
              </router-link>
            </swiper-slide>
            <!-- <div class="swiper-pagination" slot="pagination"></div> -->
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PageLoader from "@/components/PageLoader.vue";
// import HeaderComp1 from "@/components/HeaderComp1.vue";
//import InternalNavbar from "@/components/InternalNavbar.vue";
// import { Carousel3d, Slide } from "vue-carousel-3d";

export default {
  components: {
    PageLoader,
    //HeaderComp1,
  //InternalNavbar
    // Carousel3d,
    // Slide
  },
  data: function() {
    return {
      row: [],
      result: []
    };
  },
  computed: {
    getFullPath() {
      return this.$route.path;
    }
  },
  watch: {
    getFullPath() {
      this.get_row();
      this.get_result();
    }
  },
  methods: {
    get_result: function() {
      var vm = this;
      this.$http.get("more_projects").then(response => {
        vm.result = response.data.data;
      });
      this.$emit("updateHead");
    },
    get_row: function() {
      var vm = this;
      this.$http
        .get("project/" + this.$route.params.id)
        .then(response => {
          vm.row = response.data.data;
          if (Object.keys(vm.row).length == 2) {
            this.$router.push("/404");
          }
        })
        .catch(error => console.log(error));
    },
     scrollToTop() {
        window.scrollTo(0,0);
      }
  },
  mounted() {
    this.get_row();
    this.get_result();
  },
  created: function() {}
};
</script>
