<template>
  <div>
    <!-- <HeaderComp1 /> -->
    <section id="careers-page" class="content-height">
      <PageLoader />
      <!--<InternalNavbar />-->
      <div id="careers">
        <div class="hr"></div>
        <div class="container">
          <div class="content">
          <div class="titleS">
            <span>{{ $t("title.careers") }}</span>
          </div>
            <!-- <form> -->
            <!-- enctype="multipart/form-data"> -->
            <div class="row">
              <!-- <div class="form-group col-md-6 select-custom">
                <label>{{$t("careers_type")}}</label>
                <select class="form-control selc-valid">
                  <option disabled selected>-- {{$t("careers_type")}} --</option>
                  <option value="Accountant">Accountant</option>
                  <option value="Office Manager">Office Manager</option>
                  <option value="1">Other</option>
                </select>
              </div> -->
              <div class="col-md-12">
                <div class="form-sec" id="form1">
                  <!-- <h4 class="text-center"><span>Career</span> form</h4> -->
                  <div class="valid-feedback-r"><span>{{$t('success')}}.</span></div>
                  <div class="invalid-feedback-r"><span>{{$t('error')}}.</span></div>
                  <div class="row">
                    <div class="form-group col-md-6">
                      <label>{{$t("name")}}</label>
                      <input
                        type="text"
                        class="form-control"
                        :placeholder="$t('name')"
                        v-model.trim="$v.name.$model"
                        :class="{
                            'is-invalid': $v.name.$error,
                            'is-valid': !$v.name.$invalid
                          }"
                      />
                      <div class="valid-feedback">
                        <span>{{$t("name")}} is correct</span>
                      </div>
                      <div class="invalid-feedback">
                        <span v-if="!$v.name.required">• {{$t("name")}} {{$t("c2")}}</span>
                        <span v-if="!$v.name.minLength">
                          • {{$t("name")}} {{$t("c3")}}
                          {{ $v.name.$params.minLength.min }} {{$t("c5")}}
                        </span>
                        <span v-if="!$v.name.maxLength">
                          • {{$t("name")}} {{$t("c4")}}
                          {{ $v.name.$params.maxLength.max }} {{$t("c5")}}
                        </span>
                      </div>
                    </div>
                    <div class="form-group col-md-6">
                      <label>{{$t("email")}}</label>
                      <input
                        type="email"
                        class="form-control"
                        :placeholder="$t('email')"
                        v-model.trim="$v.email.$model"
                        :class="{
                            'is-invalid': $v.email.$error,
                            'is-valid': !$v.email.$invalid
                          }"
                      />
                      <div class="valid-feedback">
                        <span>• {{$t('email')}} {{$t("c6")}}</span>
                      </div>
                      <div class="invalid-feedback">
                        <span v-if="!$v.email.required">• {{$t('email')}} {{$t("c2")}}</span>
                        <span v-if="!$v.email.inUnique">• {{$t('email')}} {{$t("c7")}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-md-6">
                      <label>{{$t("mobile")}}</label>
                      <input
                        type="text"
                        class="form-control"
                        id="phone"
                        :placeholder="$t('mobile')"
                        v-model.trim="$v.mobile.$model"
                        :class="{
                            'is-invalid': $v.mobile.$error,
                            'is-valid': !$v.mobile.$invalid
                          }"
                      />
                      <div class="valid-feedback">
                        <span>• {{$t('mobile')}} {{$t('c1')}}</span>
                      </div>
                      <div class="invalid-feedback">
                        <span v-if="!$v.mobile.required">• {{$t('mobile')}} {{$t("c2")}}</span>
                        <span v-if="!$v.mobile.minLength">
                          • {{$t('mobile')}} {{$t("c3")}}
                          {{ $v.mobile.$params.minLength.min }} {{$t("c8")}}
                        </span>
                        <span v-if="!$v.mobile.maxLength">
                          • {{$t('mobile')}} {{$t("c4")}}
                          {{ $v.mobile.$params.maxLength.max }} {{$t("c8")}}
                        </span>
                        <span v-if="!$v.mobile.inUnique">• {{$t('mobile')}} {{$t("c7")}}</span>
                      </div>
                    </div>
                    <div class="form-group col-md-6">
                      <label>{{$t("cv")}}:</label>
                      <input
                        type="file"
                        id="file"
                        ref="file"
                        v-on:change="handleFileUpload()"
                        class="form-control"
                        :placeholder="$t('cv')"
                        name="file"
                      />
                      <div class="valid-feedback valid-cv">
                        <span>{{$t('cv')}} : {{$t('c1')}}</span>
                      </div>
                      <div class="invalid-feedback invalid-cv">
                        <span>
                          • {{$t('cv')}} : {{$t("c9")}}
                        </span>
                      </div>
                      <div class="invalid-feedback invalid-cv2">
                        <span>
                          • {{$t('cv')}} : {{$t("c2")}}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-md-6">
                      <label>{{$t("job")}}:</label>
                      <input
                        type="text"
                        class="form-control selc-valid2"
                        :placeholder="$t('job')"
                        v-model.trim="$v.subject.$model"
                        :class="{
                            'is-invalid': $v.subject.$error,
                            'is-valid': !$v.subject.$invalid
                          }"
                      />
                      <div class="valid-feedback">
                        <span>{{$t('job')}} {{$t('c1')}}</span>
                      </div>
                      <div class="invalid-feedback">
                        <span v-if="!$v.subject.required">• {{$t('job')}} {{$t("c2")}}</span>
                        <span v-if="!$v.subject.minLength">
                          • {{$t('job')}} {{$t("c3")}}
                          {{ $v.subject.$params.minLength.min }} {{$t("c5")}}
                        </span>
                        <span v-if="!$v.subject.maxLength">
                          • {{$t('job')}} {{$t("c4")}}
                          {{ $v.subject.$params.maxLength.max }} {{$t("c5")}}
                        </span>
                      </div>
                    </div>
                    <div class="form-group col-md-6">
                      <label>{{$t("title.messages")}}:</label>
                      <textarea
                        class="form-control"
                        id="message"
                        :placeholder="$t('title.messages')"
                        name="message"
                        rows="1"
                        v-model.trim="$v.message.$model"
                        :class="{
                            'is-invalid': $v.message.$error,
                            'is-valid': !$v.message.$invalid
                          }"
                      ></textarea>
                      <div class="valid-feedback">
                        <span>{{$t('title.messages')}} {{$t('c1')}}</span>
                      </div>
                      <div class="invalid-feedback">
                        <span v-if="!$v.message.required">• {{$t('title.messages')}} {{$t("c2")}}</span>
                        <span v-if="!$v.message.minLength">
                          • {{$t('title.messages')}} {{$t("c3")}}
                          {{ $v.message.$params.minLength.min }} {{$t("c5")}}
                        </span>
                        <span v-if="!$v.message.maxLength">
                          • {{$t('title.messages')}} {{$t("c4")}}
                          {{ $v.message.$params.maxLength.max }} {{$t("c5")}}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div id="alert-success" class="valid-feedback"></div>
                  <div id="alert-danger" class="invalid-feedback"></div>
                  <div class="text-center">
                    <button @click="formSubmitCareer()" class="btn btn-default">{{$t("submit")}}</button>
                  </div>
                </div>
              </div>
            </div>
            <!-- </form> -->
          </div>
        </div>
            <div id="loading_c" style="margin:3em 0 5em 0; display:none"></div>
      </div>
    </section>
    <notifications group="foo" />
  </div>
</template>

<script>
import PageLoader from "@/components/PageLoader.vue";
// import HeaderComp1 from "@/components/HeaderComp1.vue";
//import InternalNavbar from "@/components/InternalNavbar.vue";
import $ from "jquery";
import {
  required,
  minLength,
  maxLength,
  email
} from "vuelidate/lib/validators";

export default {
  name: "CareerComp",
  cv: 0,
  props: {
    msg: String
  },
  data() {
    return {
      name: "",
      email: "",
      mobile: "",
      file: "",
      subject: "",
      message: "",
      output: "",
      stat: "",
      countform: 0
    };
  },
  validations: {
    name: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(20)
    },
    subject: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(40)
    },
    message: {
      required,
      minLength: minLength(5),
      maxLength: maxLength(40)
    },
    cv: {
      // required
    },
    email: {
      required,
      email,
      inUnique(value) {
        if (value === "") return true;

        var email_regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;

        return new Promise(resolve => {
          setTimeout(() => {
            resolve(email_regex.test(value));
          }, 350 + Math.random() * 300);
        });
      }
    },
    mobile: {
      required,
      minLength: minLength(11),
      maxLength: maxLength(11),
      inUnique(value) {
        if (value === "") return true;

        // var email_regex = /^([(+]*[0-9]+[()+. -]*)$/;
        var email_regex = /^([(+]*[0-9]*)$/;

        return new Promise(resolve => {
          setTimeout(() => {
            resolve(email_regex.test(value));
          }, 350 + Math.random() * 300);
        });
      }
    }
  },
  // watch: {},
  // methods: {},
  components: {
    PageLoader,
    //HeaderComp1,
  //InternalNavbar
  },
  mounted: function() {
    // this.loading(7000);
    $(document).ready(function() {
      $(".selc-valid").on("change", function() {
        if ($(this).val() == "1") {
          $(this)
            .parents("form")
            .find(".selc-valid2");
          // .prop("disabled", false);
          $(this)
            .parents("form")
            .find(".selc-valid2")
            .val("");
        } else {
          $(this)
            .parents("form")
            .find(".selc-valid2")
            .val($(this).val());
          $(this)
            .parents("form")
            .find(".selc-valid2");
          // .prop("disabled", true);
        }
      });
    });

    var file1 = document.getElementById('file');
    file1.addEventListener('change', function() {
      if(file1.files[0].type == 'application/pdf')
      {
        $('.valid-cv').fadeIn();
        $('.invalid-cv').fadeOut(0);
        $('.invalid-cv2').fadeOut(0);
        // cv=1;
      }else{
        document.querySelector('#file').value = '';
        $('.invalid-cv').fadeIn();
        $('.valid-cv').fadeOut(0);
        $('.invalid-cv2').fadeOut(0);
        // cv=2;
      }
    }, false);
    
  },
  methods: {
    formSubmitCareer() {
      // if (this.countform < 3) {
      //   this.countform++;

        this.$v.$touch();

      var vidFileLength = $("#file")[0].files.length;
        if(vidFileLength === 0){   
           $('.invalid-cv2').fadeIn();
          $('.valid-cv').fadeOut(0);
          $('.invalid-cv').fadeOut(0);
          return;
        }

    
        if (this.$v.$invalid) {
          this.$submitstatus = "fail";
        } else {
          let formData = new FormData();
          formData.append("file", this.file);
            $("#loading_c").show();
          // e.preventDefault();
          // let currentObj = this;
          this.$http
            .post(
              "careers?email=" +
                this.email +
                "&name=" +
                this.name +
                "&subject=" +
                this.subject +
                "&mobile=" +
                this.mobile +
                "&message=" +
                this.message,
                formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data"
                }
              }
            )
            .then(function(response) {
              // console.log(response);
              
              // alert("Your request has been sent successfully");
              $("#loading_c").hide();
              $(".invalid-feedback-r").fadeOut();
              $(".valid-feedback-r").fadeIn();
              // this.$notify({
              //   group: 'foo',
              //   type: 'success',
              //   title: 'Important message',
              //   text: 'Your request has been sent successfully'
              // });
              return response;
              
            })
            .catch(function(error) {
              // this.$notify({
              //   group: 'foo',
              //   type: 'error',
              //   title: 'Important message',
              //   text: 'This mail has not been sent successfully'
              // });
              // alert('This mail has not been sent successfully: ' + error.message);
              $("#loading_c").hide();
              $(".valid-feedback-r").fadeOut();
              $(".invalid-feedback-r").fadeIn();
              return error;
            });

          // this.$notify({
          //   group: "foo",
          //   type: this.stat,
          //   title: "Important message",
          //   text: this.output
          // });
          this.clear();
        }
      // }
      
    },
    clear(){
      this.email = '';
      this.name = '';
      this.file = '';
      this.subject = '';
      this.message = '';
      this.mobile = '';
      this.telephone = '';
      this.fname = '';
      this.lname = '';
      this.fax = '';
      document.querySelector('#file').value = '';
        $('.invalid-cv2').fadeIn(0);
      $('.invalid-cv').fadeOut();
        $('.valid-cv').fadeOut(0);

    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    }
    }
};
</script>
