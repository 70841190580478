<template>
  <div>
    <!-- <HeaderComp1 /> -->
    <section id="contact-page" class="content-height">
      <PageLoader />
      <!--<InternalNavbar />-->
      <div id="contact-us-internal">
        <div class="hr"></div>
        <div class="container">
          <div class="content">
          <div class="titleS">
            <span>{{ $t("title.contact_us") }}</span>
          </div>
            <div class="row justify-content-center">
              <!-- <div class="col-lg-6">
                <div class="row row-cust">
                  <div class="col-2">
                    <img class="img-fluid" src="../assets/images/clock.png" alt />
                  </div>
                  <div class="col-10">
                    <h5>{{$t("working_hour")}}</h5>
                    <p>{{row.work_hour}}</p>
                  </div>
                </div>
              </div> -->
              <div class="row row-c">
                <div class="col-md-12">
                  <h5 class="d-flex">{{$t("location")}} <a href="https://goo.gl/maps/aEeq9HG8tSvTrc9q8"><p>{{row.address}}</p></a></h5>
                </div>
                <div class="col-lg-12">
                  <h5 class="d-flex">{{$t("telephone")}} <a :href="'tel:'+row.telephone"><p>{{row.telephone}}</p></a></h5>
                </div>
                <div class="col-lg-12">
                  <h5 class="d-flex">{{$t("fax")}} <a :href="'tel:'+row.fax"><p>{{row.fax}}</p></a></h5>
                </div>
                <div class="col-lg-12">
                  <h5 class="d-flex">{{$t("email")}} <a :href="'mailto:'+row.email">
                    <p>{{row.email}}</p>
                  </a></h5>
                </div>
              </div>

              <!-- <div class="col-lg-6">
                <div class="row row-cust">
                  <div class="col-2">
                    <img class="img-fluid" src="../assets/images/location.png" alt />
                  </div>
                  <div class="col-10">
                    <h5>{{$t("location")}}</h5>
                    <p>{{row.address}}</p>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="row row-cust">
                  <div class="col-2">
                    <img class="img-fluid" src="../assets/images/telephone.png" alt />
                  </div>
                  <div class="col-10">
                    <h5>{{$t("telephone")}}</h5>
                    <p>{{row.telephone}}</p>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="row row-cust">
                  <div class="col-2">
                    <img class="img-fluid" src="../assets/images/fax.png" alt />
                  </div>
                  <div class="col-10">
                    <h5>{{$t("fax")}}</h5>
                    <p>{{row.fax}}</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="row row-cust">
                  <div class="col-2">
                    <img class="img-fluid" src="../assets/images/email.png" alt />
                  </div>
                  <div class="col-10">
                    <h5>{{$t("email")}}</h5>
                    <a :href="'mailto:'+row.email">
                      <p>{{row.email}}</p>
                    </a>
                  </div>
                </div>
              </div> -->
            </div>
            <div class="row mt-4">
              <div class="col-md-12">
                <div class="form-sec" id="form1">
                  <p class="text-center">{{ $t("contact_subscribe") }}</p>
                  <div class="row">
                    <div class="form-group col-md-6">
                      <label>{{$t("first_name")}}</label>
                      <input
                        type="text"
                        class="form-control"
                        :placeholder="$t('first_name')"
                        v-model.trim="$v.fname.$model"
                        :class="{
                            'is-invalid': $v.fname.$error,
                            'is-valid': !$v.fname.$invalid
                          }"
                      />
                      <div class="valid-feedback">
                        <span>{{$t('first_name')}} {{$t('c1')}}</span>
                      </div>
                      <div class="invalid-feedback">
                        <span v-if="!$v.fname.required">• {{$t('first_name')}} {{$t("c2")}}</span>
                        <span v-if="!$v.fname.minLength">
                          • {{$t('first_name')}} {{$t("c3")}}
                          {{ $v.fname.$params.minLength.min }} {{$t("c5")}}
                        </span>
                        <span v-if="!$v.fname.maxLength">
                          • {{$t('first_name')}} {{$t("c4")}}
                          {{ $v.fname.$params.maxLength.max }} {{$t("c5")}}
                        </span>
                      </div>
                    </div>
                    <div class="form-group col-md-6">
                      <label>{{$t("last_name")}}</label>
                      <input
                        type="text"
                        class="form-control"
                        :placeholder="$t('last_name')"
                        v-model.trim="$v.lname.$model"
                        :class="{
                            'is-invalid': $v.lname.$error,
                            'is-valid': !$v.lname.$invalid
                          }"
                      />
                      <div class="valid-feedback">
                        <span>{{$t('last_name')}} {{$t('c1')}}</span>
                      </div>
                      <div class="invalid-feedback">
                        <span v-if="!$v.lname.required">• {{$t('last_name')}} {{$t("c2")}}</span>
                        <span v-if="!$v.lname.minLength">
                          • {{$t('last_name')}} {{$t("c3")}}
                          {{ $v.lname.$params.minLength.min }} {{$t("c5")}}
                        </span>
                        <span v-if="!$v.lname.maxLength">
                          • {{$t('last_name')}} {{$t("c4")}}
                          {{ $v.lname.$params.maxLength.max }} {{$t("c5")}}
                        </span>
                      </div>
                    </div>
                    <div class="form-group col-md-6">
                      <label>{{$t("email")}}</label>
                      <input
                        type="email"
                        class="form-control"
                        :placeholder="$t('email')"
                        v-model.trim="$v.email.$model"
                        :class="{
                            'is-invalid': $v.email.$error,
                            'is-valid': !$v.email.$invalid
                          }"
                      />
                      <div class="valid-feedback">
                        <span>• {{$t('email')}} {{$t("c6")}}</span>
                      </div>
                      <div class="invalid-feedback">
                        <span v-if="!$v.email.required">• {{$t('email')}} {{$t("c2")}}</span>
                        <span v-if="!$v.email.inUnique">• {{$t('email')}} {{$t("c7")}}</span>
                      </div>
                    </div>
                    <div class="form-group col-md-6">
                      <label>{{$t("mobile")}}</label>
                      <input
                        type="text"
                        class="form-control"
                        id="phone"
                        :placeholder="$t('mobile')"
                        v-model.trim="$v.mobile.$model"
                        :class="{
                            'is-invalid': $v.mobile.$error,
                            'is-valid': !$v.mobile.$invalid
                          }"
                      />
                      <div class="valid-feedback">
                        <span>• {{$t('mobile')}} {{$t('c1')}}</span>
                      </div>
                      <div class="invalid-feedback">
                        <span v-if="!$v.mobile.required">• {{$t('mobile')}} {{$t("c2")}}</span>
                        <span v-if="!$v.mobile.minLength">
                          • {{$t('mobile')}} {{$t("c3")}}
                          {{ $v.mobile.$params.minLength.min }} {{$t("c8")}}
                        </span>
                        <span v-if="!$v.mobile.maxLength">
                          • {{$t('mobile')}} {{$t("c4")}}
                          {{ $v.mobile.$params.maxLength.max }} {{$t("c8")}}
                        </span>
                        <span v-if="!$v.mobile.inUnique">• {{$t('mobile')}} {{$t("c7")}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-md-6">
                      <label>{{$t("telephone")}}</label>
                      <input
                        type="text"
                        class="form-control"
                        id="telephone"
                        :placeholder="$t('telephone')"
                        v-model.trim="$v.telephone.$model"
                        :class="{
                            'is-invalid': $v.telephone.$error,
                            'is-valid': !$v.telephone.$invalid
                          }"
                      />
                      <div class="valid-feedback">
                        <span>• {{$t('telephone')}} {{$t('c1')}}</span>
                      </div>
                      <div class="invalid-feedback">
                        <span v-if="!$v.telephone.required">• {{$t('telephone')}} {{$t("c2")}}</span>
                        <span v-if="!$v.telephone.minLength">
                          • {{$t('telephone')}} {{$t("c3")}}
                          {{ $v.telephone.$params.minLength.min }} {{$t("c8")}}
                        </span>
                        <span v-if="!$v.telephone.maxLength">
                          • {{$t('telephone')}} {{$t("c4")}}
                          {{ $v.telephone.$params.maxLength.max }} {{$t("c8")}}
                        </span>
                        <span v-if="!$v.telephone.inUnique">• {{$t('telephone')}} {{$t("c7")}}</span>
                      </div>
                    </div>
                    <div class="form-group col-md-6">
                      <label>{{$t("fax")}}:</label>
                      <input
                        type="text"
                        class="form-control"
                        :placeholder="$t('fax')"
                        v-model.trim="$v.fax.$model"
                        :class="{
                            'is-invalid': $v.fax.$error,
                            'is-valid': !$v.fax.$invalid
                          }"
                      />
                      <div class="valid-feedback">
                        <span>• {{$t("fax")}} {{$t('c1')}}</span>
                      </div>
                      <div class="invalid-feedback">
                        <span v-if="!$v.fax.required">• {{$t('fax')}} {{$t("c2")}}</span>
                        <span v-if="!$v.fax.minLength">
                          • {{$t('fax')}} {{$t("c3")}}
                          {{ $v.fax.$params.minLength.min }} {{$t("c8")}}
                        </span>
                        <span v-if="!$v.fax.maxLength">
                          • {{$t('fax')}} {{$t("c4")}}
                          {{ $v.fax.$params.maxLength.max }} {{$t("c8")}}
                        </span>
                        <span v-if="!$v.fax.inUnique">• {{$t('fax')}} {{$t("c7")}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-md-6">
                      <label>{{$t("department")}}</label>
                      <input
                        type="text"
                        class="form-control selc-valid2"
                        :placeholder="$t('department')"
                        v-model.trim="$v.sector.$model"
                        :class="{
                            'is-invalid': $v.sector.$error,
                            'is-valid': !$v.sector.$invalid
                          }"
                      />
                      <div class="valid-feedback">
                        <span>{{$t('department')}} {{$t('c1')}}</span>
                      </div>
                      <div class="invalid-feedback">
                        <span v-if="!$v.sector.required">• {{$t('department')}} {{$t("c2")}}</span>
                        <span v-if="!$v.sector.minLength">
                          • {{$t('department')}} {{$t("c3")}}
                          {{ $v.sector.$params.minLength.min }} {{$t("c5")}}
                        </span>
                        <span v-if="!$v.sector.maxLength">
                          • {{$t('department')}} {{$t("c4")}}
                          {{ $v.sector.$params.maxLength.max }} {{$t("c5")}}
                        </span>
                      </div>
                    </div>
                    <div class="form-group col-md-6">
                      <label>{{$t("job")}}:</label>
                      <input
                        type="text"
                        class="form-control selc-valid2"
                        :placeholder="$t('job')"
                        v-model.trim="$v.job.$model"
                        :class="{
                            'is-invalid': $v.job.$error,
                            'is-valid': !$v.job.$invalid
                          }"
                      />
                      <div class="valid-feedback">
                        <span>{{$t('job')}} {{$t('c1')}}</span>
                      </div>
                      <div class="invalid-feedback">
                        <span v-if="!$v.job.required">• {{$t('job')}} {{$t("c2")}}</span>
                        <span v-if="!$v.job.minLength">
                          • {{$t('job')}} {{$t("c3")}}
                          {{ $v.job.$params.minLength.min }} {{$t("c5")}}
                        </span>
                        <span v-if="!$v.job.maxLength">
                          • {{$t('job')}} {{$t("c4")}}
                          {{ $v.job.$params.maxLength.max }} {{$t("c5")}}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-md-6">
                      <label>{{$t("country")}}:</label>
                      <input
                        type="text"
                        class="form-control selc-valid2"
                        :placeholder="$t('country')"
                        v-model.trim="$v.country.$model"
                        :class="{
                            'is-invalid': $v.country.$error,
                            'is-valid': !$v.country.$invalid
                          }"
                      />
                      <div class="valid-feedback">
                        <span>{{$t('country')}} {{$t('c1')}}</span>
                      </div>
                      <div class="invalid-feedback">
                        <span v-if="!$v.country.required">• {{$t('country')}} {{$t("c2")}}</span>
                        <span v-if="!$v.country.minLength">
                          • {{$t('country')}} {{$t("c3")}}
                          {{ $v.country.$params.minLength.min }} {{$t("c5")}}
                        </span>
                        <span v-if="!$v.country.maxLength">
                          • {{$t('country')}} {{$t("c4")}}
                          {{ $v.country.$params.maxLength.max }} {{$t("c5")}}
                        </span>
                      </div>
                    </div>
                    <div class="form-group col-md-6">
                      <label>{{$t("company")}}:</label>
                      <input
                        type="text"
                        class="form-control selc-valid2"
                        :placeholder="$t('company')"
                        v-model.trim="$v.company.$model"
                        :class="{
                            'is-invalid': $v.company.$error,
                            'is-valid': !$v.company.$invalid
                          }"
                      />
                      <div class="valid-feedback">
                        <span>{{$t('company')}} {{$t('c1')}}</span>
                      </div>
                      <div class="invalid-feedback">
                        <span v-if="!$v.company.required">• {{$t('company')}} {{$t("c2")}}</span>
                        <span v-if="!$v.company.minLength">
                          • {{$t('company')}} {{$t("c3")}}
                          {{ $v.company.$params.minLength.min }} {{$t("c5")}}
                        </span>
                        <span v-if="!$v.company.maxLength">
                          • {{$t('company')}} {{$t("c4")}}
                          {{ $v.company.$params.maxLength.max }} {{$t("c5")}}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div id="alert-success" class="valid-feedback"></div>
                  <div id="alert-danger" class="invalid-feedback"></div>
                  <div class="text-center">
                    <button @click="formSubmitContact()" class="btn btn-default">{{$t("submit")}}</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3453.8432073703816!2d31.219610684930622!3d30.041355781883503!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x145840d5850c2177%3A0xe0452d63fffb66f9!2z2KfZhNin2KrYrdin2K8g2KfZhNmF2LXYsdmJINmE2YTYutix2YEg2KfZhNiz2YrYp9it2YrYqQ!5e0!3m2!1sar!2seg!4v1584619868023!5m2!1sar!2seg"
                width="100%"
                height="100%"
                frameborder="0"
                style="border:0;"
                allowfullscreen
                aria-hidden="false"
                tabindex="0"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PageLoader from "@/components/PageLoader.vue";
// import HeaderComp1 from "@/components/HeaderComp1.vue";
//import InternalNavbar from "@/components/InternalNavbar.vue";
import {
  required,
  minLength,
  maxLength,
  email
} from "vuelidate/lib/validators";

export default {
  components: {
    PageLoader,
    //HeaderComp1,
  //InternalNavbar
  },
  data: function() {
    return {
      fname: "",
      lname: "",
      email: "",
      mobile: "",
      telephone: "",
      fax: "",
      sector: "",
      job: "",
      company: "",
      country: "",
      output: "",
      stat: "",
      countform: 0,
      
      row: []
    };
  },
  validations: {
    fname: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(20)
    },
    lname: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(20)
    },
    sector: {
      required,
      minLength: minLength(5),
      maxLength: maxLength(35)
    },
    job: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(35)
    },
    company: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(35)
    },
    country: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(30)
    },
    email: {
      required,
      email,
      inUnique(value) {
        if (value === "") return true;

        var email_regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;

        return new Promise(resolve => {
          setTimeout(() => {
            resolve(email_regex.test(value));
          }, 350 + Math.random() * 300);
        });
      }
    },
    mobile: {
      required,
      minLength: minLength(11),
      maxLength: maxLength(11),
      inUnique(value) {
        if (value === "") return true;

        var email_regex = /^([(+]*[0-9]*)$/;
        // var email_regex = /^([(+]*[0-9]+[()+. -]*)$/;

        return new Promise(resolve => {
          setTimeout(() => {
            resolve(email_regex.test(value));
          }, 350 + Math.random() * 300);
        });
      }
    },
    fax: {
      required,
      minLength: minLength(10),
      maxLength: maxLength(14),
      inUnique(value) {
        if (value === "") return true;

        var email_regex = /^([(+]*[0-9]+[()+. -]*)$/;

        return new Promise(resolve => {
          setTimeout(() => {
            resolve(email_regex.test(value));
          }, 350 + Math.random() * 300);
        });
      }
    },
    telephone: {
      required,
      minLength: minLength(10),
      maxLength: maxLength(12),
      inUnique(value) {
        if (value === "") return true;

        var email_regex = /^([(+]*[0-9]+[()+. -]*)$/;

        return new Promise(resolve => {
          setTimeout(() => {
            resolve(email_regex.test(value));
          }, 350 + Math.random() * 300);
        });
      }
    }
  },
  methods: {
    formSubmitContact() {
      // if (this.countform < 1) {
        this.$v.$touch();
      //   this.countform++;
        if (this.$v.$invalid) {
          this.$submitstatus = "fail";
        } else {
          // e.preventDefault();
          let currentObj = this;
          this.$http
            .post(
              "message?email=" +
                this.email +
                "&fname=" +
                this.lname +
                "&lname=" +
                this.fname +
                "&telephone=" +
                this.telephone +
                "&mobile=" +
                this.mobile +
                "&sector=" +
                this.sector +
                "&job=" +
                this.job +
                "&company=" +
                this.company +
                "&country=" +
                this.country +
                "&fax=" +
                this.fax
            )
            .then(function(response) {
              currentObj.output = response.data;
              currentObj.output = "Your request has been sent successfully";
              currentObj.stat = "success";
              
            })
            .catch(function(error) {
              currentObj.stat = "error";
              // currentObj.output = error;
              currentObj.output = "This mail is already subscribed OR " + error;
              // console.log("faild");
            });

          this.$notify({
            group: "foo",
            type: "success",
            title: "Important message",
            text: "Your request has been sent successfully"
          });
          this.clear();
          // this.$notify({
          //   group: "foo",
          //   type: this.stat,
          //   title: "Important message",
          //   text: this.output
          // });
        }
      // } else {
      //   this.$notify({
      //     group: "foo",
      //     type: "error",
      //     title: "Wrong message",
      //     text: "Please stop sending, your request has already been sent"
      //   });
      // }
    },
    clear(){
      this.email = '';
      this.company = '';
      this.country = '';
      this.job = '';
      this.sector = '';
      this.mobile = '';
      this.telephone = '';
      this.fname = '';
      this.lname = '';
      this.fax = '';
    }
  },
  mounted() {
    this.loading(7000);
    this.$http
      .get("settings")
      .then(response => {
        this.row = response.data.data;
      })
      .catch(error => console.log(error))
      .finally(() => (this.loading = false));
  }
};
</script>
