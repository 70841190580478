<template>
  <div>
    <!-- <HeaderComp1 /> -->
    <section id="trade-topics" class="content-height">
      <!--<InternalNavbar />-->
      <PageLoader />
      <div class="hr"></div>
      <div class="titleS">
        <span>{{ $t("title.trade_topics") }}</span>
      </div>
      <div class="topic">
        <div class="container-fluid">
          <div class="row" v-for="(r, i) in result.data" :key="i">
            <div class="col-md-6">
              <kinesis-container>
                <kinesis-element :strength="10">
                  <div class="img1 img" :style="{ backgroundImage: ` url(http://etf-admin.ispdemos.com/storage/app/public/${r.second_img})` }">
                    <!-- <img
                      :title="r[$i18n.locale+'_title']"
                      class="img-fluid"
                      :src="'http://etf-admin.ispdemos.com/storage/app/public/' + r.second_img"
                      :alt="r[$i18n.locale+'_title']"
                    /> -->
                    <!-- <div class="img" :style="{ backgroundImage: ` url(http://etf-admin.ispdemos.com/storage/app/public/${r.second_img})` }">

                    </div> -->
                  </div>
                </kinesis-element>
                <kinesis-element :strength="50">
                  <div class="img2 img" :style="{ backgroundImage: ` url(http://etf-admin.ispdemos.com/storage/app/public/${r.img})` }">
                    <!-- <img
                      :title="r[$i18n.locale+'_title']"
                      class="img-fluid"
                      :src="'http://etf-admin.ispdemos.com/storage/app/public/' + r.img"
                      :alt="r[$i18n.locale+'_title']"
                    /> -->
                    <!-- <div class="img" :style="{ backgroundImage: ` url(http://etf-admin.ispdemos.com/storage/app/public/${r.img})` }">

                    </div> -->
                  </div>
                </kinesis-element>
              </kinesis-container>
            </div>
            <div class="col-md-6">
              <kinesis-container>
                <kinesis-element :strength="10">
                  <div class="text-pralex">
                    <h2>{{r[$i18n.locale+'_title']}}</h2>
                  </div>
                </kinesis-element>
              </kinesis-container>
              <p>{{r[$i18n.locale+'_desc']}}</p>
            </div>
          </div>
            <div v-if=" (result.data && result.data.length ? result.data.length : 0) == 0">
                <div id="loading_c"></div>
              <h2 id="not_found" style="color:black;">{{ $t("not_found") }}</h2>
            </div>
          <nav aria-label="Page navigation example">
            <pagination :align="'center'" :data="result" @pagination-change-page="get_result">
              <span slot="prev-nav" v-html="$t('pagination.previous')"></span>
              <span slot="next-nav" v-html="$t('pagination.next')"></span>
            </pagination>
          </nav>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PageLoader from "@/components/PageLoader.vue";
// import HeaderComp1 from "@/components/HeaderComp1.vue";
//import InternalNavbar from "@/components/InternalNavbar.vue";
import Parallax from "parallax-js";
export default {
  components: {
    PageLoader,
    //HeaderComp1,
  //InternalNavbar
  },
  data: function() {
    return {
      result: { total: 0 }
    };
  },
  methods: {
    get_result: function(page = 1) {
      var vm = this;
      this.$http.get("trade?count=10&page=" + page).then(response => {
        vm.result = response.data;
      });
      this.$emit("updateHead");
      this.loaded = false;
    }
  },
  mounted: function() {
    this.loading(7000);
    var scene = document.getElementsByClassName("scene");
    for (var i = 0; i < scene.length; i++) {
      var parallaxInstance = new Parallax(scene.item(i));
    }
    return parallaxInstance;

  },
  created: function() {
    this.get_result();
  }
};
</script>
