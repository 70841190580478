<template>
  <div>
    <!-- <HeaderComp1 /> -->
    <section id="board-members-internal-page" class="content-height">
      <!--<InternalNavbar />-->
      <PageLoader />
      <div class="hr"></div>
      <div class="container">
        <div class="titleS">
          <span>{{ row[$i18n.locale + "_title"] }}</span>
        </div>
        <div class="content">
          <div class="row justify-content-center bootstrap">
            <div
              class="col-md-6 col-lg-4 col-xl-3"
              v-for="(r, index) in row2"
              :key="r.img + index"
            >
              <div class="card">
                <!-- <img
                  :title="r[$i18n.locale + '_title']"
                  class="img-fluid"
                  :src="
                    'http://etf-admin.ispdemos.com/storage/app/public/' + r.img
                  "
                  :alt="r[$i18n.locale + '_title']"
                /> -->
                <div class="img" :style="{ backgroundImage: ` url(http://etf-admin.ispdemos.com/storage/app/public/${r.img})` }">

                </div>
                <div class="card-info">
                  <li>{{ r[$i18n.locale + "_name"] }}</li>
                  <li>{{ r[$i18n.locale + "_group_name"] }}</li>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PageLoader from "@/components/PageLoader.vue";
// import HeaderComp1 from "@/components/HeaderComp1.vue";
//import InternalNavbar from "@/components/InternalNavbar.vue";
export default {
  components: {
    PageLoader,
    //HeaderComp1,
  //InternalNavbar
  },
  data: function() {
    return {
      
      row: [],
      row2: []
    };
  },
  methods: {},
  mounted() {
    
    this.$http
      .get("councils/" + this.$route.params.id)
      .then(response => {
        this.row = response.data.data;
        if (Object.keys(this.row).length == 2) {
          this.$router.push("/404");
        }
      })
      .catch(error => console.log(error))
      .finally(() => (this.loading = false));

    this.$http
      .get("councils-member/" + this.$route.params.id)
      .then(response => {
        this.row2 = response.data;
        if (Object.keys(this.row2).length == 2) {
          this.$router.push("/404");
        }
      })
      .catch(error => console.log(error))
      .finally(() => (this.loading = false));
  }
};
</script>
