<template>
  <div>
    <!-- <HeaderComp1 /> -->
    <section id="project-internal" class="content-height">
      <!--<InternalNavbar />-->
      <PageLoader />
      <div class="hr"></div>
      <div class="container">
        <div class="titleS">
          <span>{{ $t("title.projects") }}</span>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-6 col-lg-4 col-xl-3" v-for="(r, i) in result.data" :key="r.en_slug+i">
            <div class="card-custom">
              <router-link :to="'projects/' + r.en_slug" tag="a">
                <!-- <img
                  class="img-fluid"
                  :src="'http://etf-admin.ispdemos.com/storage/app/public/' + r.img"
                  :alt="r[$i18n.locale+'_title']"
                /> -->
                
                <div class="img-projects" :style="{ backgroundImage: ` url(http://etf-admin.ispdemos.com/storage/app/public/${r.img})` }">

                </div>

              </router-link>
              <div class="content">
                <router-link :to="'projects/' + r.en_slug" tag="a">
                <h2>{{ r[$i18n.locale+'_title'] }}</h2>
              </router-link>
                <!-- <h2>{{r.slug }}</h2> -->

                <p v-html="getPragraphLimit(r[$i18n.locale+'_desc'], 140)">
                  <!-- <p v-html="getPragraphLimit(r.description)">
                  </p>-->
                  
                </p>
                  <router-link :to="'projects/' + r.en_slug" tag="a">{{ $t("read_more") }}</router-link>
              </div>
            </div>
          </div>
        </div>
        <div v-if=" (result.data && result.data.length ? result.data.length : 0) == 0">
              <div id="loading_c"></div>
              <h2 id="not_found" style="color:black;">{{ $t("not_found") }}</h2>
        </div>
        <div class="row justify-content-center">
          <nav aria-label="Page navigation example">
            <pagination :align="'center'" :data="result" @pagination-change-page="get_result">
              <span slot="prev-nav" v-html="$t('pagination.previous')"></span>
              <span slot="next-nav" v-html="$t('pagination.next')"></span>
            </pagination>
          </nav>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PageLoader from "@/components/PageLoader.vue";
// import HeaderComp1 from "@/components/HeaderComp1.vue";
//import InternalNavbar from "@/components/InternalNavbar.vue";
export default {
  components: {
    PageLoader,
    //HeaderComp1,
  //InternalNavbar
  },
  data: function() {
    return {
      result: {}
    };
  },
  methods: {
    get_result: function(page = 1) {
      var vm = this;
      this.$http.get("project?count=11&page=" + page).then(response => {
        // console.log(response.data);
        vm.result = response.data;
      });
      this.$emit("updateHead");
      this.loaded = false;
    }
  },
  mounted: function() {
    this.loading(7000);
    // this.get_result();
  },
  created: function() {
    this.get_result();
  }
};
</script>
