<template>
  <div>
    <!-- <HeaderComp1 /> -->
    <section id="members-page" class="content-height">
      <!--<InternalNavbar />-->
      <PageLoader />
      <div class="hr"></div>
      <div class="container">
        <div class="titleS">
          <span>{{ $t("title.members_g") }}</span>
        </div>
        <div class="content" v-for="(r, i) in result.data" :key="i">
          <div class="tittle">
            <img class="img-fluid" src="../assets/images/tittle.png" alt />
            <!-- <h2>{{r[$i18n.locale+'_title']}}</h2> -->
            <router-link :to="'members/' + r.en_slug" tag="h2" style="cursor: pointer;">
             {{r[$i18n.locale+'_title']}}
            </router-link>
          </div>
          <p>
            {{ getPragraphLimit(r[$i18n.locale+'_desc'] , 230) }}
            <router-link :to="'members/' + r.en_slug" tag="a">
              {{
              $t("read_more")
              }}
            </router-link>
          </p>
        </div>
        <div v-if=" (result.data && result.data.length ? result.data.length : 0) == 0">
              <div id="loading_c"></div>
              <h2 id="not_found" style="color:black;">{{ $t("not_found") }}</h2>
          </div>
        <nav aria-label="Page navigation example">
          <pagination :align="'center'" :data="result" @pagination-change-page="get_result">
            <span slot="prev-nav" v-html="$t('pagination.previous')"></span>
            <span slot="next-nav" v-html="$t('pagination.next')"></span>
          </pagination>
        </nav>
      </div>
    </section>
  </div>
</template>

<script>
import PageLoader from "@/components/PageLoader.vue";
// import HeaderComp1 from "@/components/HeaderComp1.vue";
//import InternalNavbar from "@/components/InternalNavbar.vue";
export default {
  components: {
    PageLoader,
    //HeaderComp1,
  //InternalNavbar
  },
  data: function() {
    return {
      result: { total: 0 }
    };
  },
  methods: {
    get_result: function(page = 1) {
      var vm = this;
      this.$http.get("group?count=11&page=" + page).then(response => {
        vm.result = response.data;
      });
      this.$emit("updateHead");
      this.loaded = false;
    }
  },
  mounted: function() {
    this.loading(7000);
    // this.get_result();
  },
  created: function() {
    this.get_result();
  }
};
</script>
