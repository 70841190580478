<template>
  <div>
    <!-- <HeaderComp1 /> -->
    <section id="news-page" class="content-height">
      <PageLoader />
      <!--<InternalNavbar />-->
      <div id="news">
        <div class="hr"></div>
        <div class="container">
          <div class="titleS">
            <span>{{ $t("title.news") }}</span>
          </div>
          <div class="search-wrapper cf">
            <input
              type="text"
              :placeholder="$t('title.search4')"
              v-model.trim="$v.search.$model"
              :class="{
                'is-invalid top-cat-field': $v.search.$error,
                'is-valid top-cat-field': !$v.search.$invalid,
                'top-cat-field': 'top-cat-field'
              }"
            />
            <router-link :to="'/news-room?input=' + this.search">
              <button type="submit">{{ $t("title.search2") }}</button>
            </router-link>
                <div class="valid-feedback">
                  <span>• {{$t('title.search')}} {{$t("c6")}}</span>
                </div>
                <div class="invalid-feedback">
                  <span v-if="!$v.search.required">• {{$t('title.search')}} {{$t("c2")}}</span>
                  <span v-if="!$v.search.minLength">
                    • {{$t('title.search')}} {{$t("c3")}}
                    {{ $v.search.$params.minLength.min }} {{$t("c5")}}
                  </span>
                </div>
          </div>
          <div class="content">
            <div
              class="news"
              v-for="(r, i) in result2.data"
              :key="r.en_title * 0.2 + i"
            >
              <!-- <h2>{{ r[$i18n.locale + "_title"] }}</h2> -->
              <router-link :to="'/news-room/' + r.en_slug" tag="h2" style="cursor: pointer;">
              {{ r[$i18n.locale + "_title"] }}
              </router-link>
              <p>
                {{ getPragraphLimit(r[$i18n.locale + "_desc"], 270) }}
                <!-- <router-link :to="'news-room/' + r.slug" tag="a">{{ $t("read_more") }}</router-link> -->
                <router-link :to="'/news-room/' + r.en_slug" tag="a">{{
                  $t("read_more")
                }}</router-link>
              </p>
              <span class="date">{{ r.date }}</span>
            </div>
            <div v-if=" (result2.data && result2.data.length ? result2.data.length : 0) == 0">
                <h2 v-if="this.$route.query.input != null">{{ $t("not_found") }}</h2>
              </div>
          </div>
          <div class="content" v-show="inputQ == '' || inputQ == null">
            <div class="news" v-for="(r, i) in result.data" :key="i">
              <!-- <h2>{{ r[$i18n.locale + "_title"] }}</h2> -->
              <router-link :to="'/news-room/' + r.en_slug" tag="h2" style="cursor: pointer;">
              {{ r[$i18n.locale + "_title"] }}
              </router-link>
              <p>
                {{ getPragraphLimit(r[$i18n.locale + "_desc"], 270) }}
                <!-- <router-link :to="'news-room/' + r.slug" tag="a">{{ $t("read_more") }}</router-link> -->
                <router-link :to="'/news-room/' + r.en_slug" tag="a">{{
                  $t("read_more")
                }}</router-link>
              </p>
              <span class="date">{{ r.date }}</span>
            </div>
            <div v-if=" (result.data && result.data.length ? result.data.length : 0) == 0">
               <div id="loading_c"></div>
                <h2 id="not_found" style="color:black;">{{ $t("not_found") }}</h2>
            </div>
            <nav aria-label="Page navigation example">
              <pagination
                :align="'center'"
                :data="result"
                @pagination-change-page="get_result"
              >
                <span slot="prev-nav" v-html="$t('pagination.previous')"></span>
                <span slot="next-nav" v-html="$t('pagination.next')"></span>
              </pagination>
            </nav>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PageLoader from "@/components/PageLoader.vue";
// import HeaderComp1 from "@/components/HeaderComp1.vue";
//import InternalNavbar from "@/components/InternalNavbar.vue";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      result: { total: 0 },
      result2: { total: 0 },
      search: ''
    };
  },
  validations: {
    search: {
      required,
      minLength: minLength(3)
    }
  },
  components: {
    PageLoader,
    //HeaderComp1,
  //InternalNavbar
  },
  computed: {
    getFullPath() {
      return this.$route.query;
    },
    inputQ() {
      return this.$route.query.input;
    }
  },
  watch: {
    getFullPath() {
      this.get_search();
    }
  },
  methods: {
    get_result: function(page = 1) {
      var vm = this;
      this.$http.get("news?count=10&page=" + page).then(response => {
        vm.result = response.data;
      });
      this.$emit("updateHead");
      this.loaded = false;
    },
    get_search: function() {
      var vm = this;
      this.$http
        .get("search-news?input=" + this.$route.query.input)
        .then(response => {
          vm.result2 = response.data;
        });
      this.$emit("updateHead");
      this.loaded = false;
    }
  },
  mounted: function() {
    this.get_result();
    this.get_search();
    this.loading(4000);
  },
  created: function() {}
};
</script>
