<template>
  <div>
    <!-- <HeaderComp1 /> -->
    <section id="affilliates-page" class="content-height">
      <!--<InternalNavbar />-->
      <PageLoader />
      <div class="hr"></div>
      <div class="container">
        <div class="titleS">
          <span>{{ $t("title.affiliates") }}</span>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-6 col-lg-4" v-for="(r, i) in result.data" :key="r.en_title+i">
            <div class="aff-card">
              <figure class="effect-chico">
                <img class="img-fluid" :src="'http://etf-admin.ispdemos.com/storage/app/public/' + r.img" alt />
                <figcaption>
                  <!-- <h2>{{ r[$i18n.locale+'_title'] }}</h2> -->
                  <router-link :to="'/affiliates/' + r.en_slug" tag="a"></router-link>
                </figcaption>
              </figure>
              <div class="discr">
                <div v-html="getPragraphLimit(r[$i18n.locale+'_desc'],200)"></div>
                <router-link :to="'/affiliates/' + r.en_slug" tag="a">{{ $t("more") }}</router-link>
              </div>
            </div>
          </div>

        </div>
          <div v-if=" (result.data && result.data.length ? result.data.length : 0) == 0">
              <div id="loading_c"></div>
              <h2 id="not_found" style="color:black;">{{ $t("not_found") }}</h2>
          </div>
        <div class="row justify-content-center">
          <nav aria-label="Page navigation example">
            <pagination :align="'center'" :data="result" @pagination-change-page="get_result">
              <span slot="prev-nav" v-html="$t('pagination.previous')"></span>
              <span slot="next-nav" v-html="$t('pagination.next')"></span>
            </pagination>
          </nav>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PageLoader from "@/components/PageLoader.vue";
// import HeaderComp1 from "@/components/HeaderComp1.vue";
//import InternalNavbar from "@/components/InternalNavbar.vue";
export default {
  components: {
    PageLoader,
    //HeaderComp1,
  //InternalNavbar
  },
  data: function() {
    return {
      result: { total: 0 }
    };
  },
  methods: {
    get_result: function(page = 1) {
      var vm = this;
      this.$http.get("affilates?count=10&page=" + page).then(response => {
        vm.result = response.data;
      });
      this.$emit("updateHead");
      this.loaded = false;
    }
  },
  mounted: function() {
    this.loading(7000);
    // this.get_result();
  },
  created: function() {
    this.get_result();
  }
};
</script>
