<template>
  <div>
    <!-- <HeaderComp1 /> -->
    <section id="information-page" class="content-height">
      <!--<InternalNavbar />-->
      <PageLoader />
      <div class="hr"></div>
      <div class="container">
        <div class="titleS">
          <span>{{ $t("title.information") }}</span>
        </div>
        <div class="content">
          <p>{{ row[$i18n.locale+'_desc'] }}</p>
          <div class="col-md-2" v-for="(r, i) in row.conditionable" :key="r.en_title+i">
            <a :href="'http://etf-admin.ispdemos.com/storage/app/public/' + r.img" target="_blank" download>
              <img
                :title="r[$i18n.locale+'_title']"
                class="img-fluid"
                width="80px"
                src="../assets/images/pdf.png"
                :alt="r[$i18n.locale + '_title']"
              />
              <span class="d-block">{{r[$i18n.locale+'_title']}}</span>
            </a>
          </div>
          <div v-if=" (row.conditionable && row.conditionable.length ? row.conditionable.length : 0) == 0">
                <div id="loading_c"></div>
              <h2 id="not_found" style="color:black;">{{ $t("not_found") }}</h2>
            </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PageLoader from "@/components/PageLoader.vue";
// import HeaderComp1 from "@/components/HeaderComp1.vue";
//import InternalNavbar from "@/components/InternalNavbar.vue";
export default {
  components: {
    PageLoader,
    //HeaderComp1,
  //InternalNavbar
  },
  data: function() {
    return {
      row: []
    };
  },
  methods: {},
  mounted() {
    this.$http
      .get("pagesBy/5")
      .then(response => {
        this.row = response.data.data;
      })
      .catch(error => console.log(error));
      
    this.loading(7000);
  }
};
</script>
