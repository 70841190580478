<template>
  <div>
    <!-- <HeaderComp1 /> -->
    <section id="partners-page" class="content-height">
      <!--<InternalNavbar />-->
      <PageLoader />
      <div class="hr"></div>
      <div class="container-fluid">
        <div class="titleS">
          <span>{{ $t("title.partners") }}</span>
        </div>
        <div v-if="loaded">
          loading
        </div>
        <div class="content">
          <div class="row justify-content-center">
            <div class="col-md-4 col-xl-2" v-for="(r, i) in result.data" :key="r.en_slug+i">
              <router-link :to="'/partners/' + r.en_slug" tag="a">
                <!-- <img
                  :title="r[$i18n.locale+'_title']"
                  class="img-fluid"
                  :src="'http://etf-admin.ispdemos.com/storage/app/public/' + r.img"
                  :alt="r[$i18n.locale + '_title']"
                /> -->
                <div class="img" :style="{ backgroundImage: ` url(http://etf-admin.ispdemos.com/storage/app/public/${r.img})` }">

                </div>
              </router-link>
            </div>

          </div>
            <div v-if=" (result.data && result.data.length ? result.data.length : 0) == 0">
                <div id="loading_c"></div>
              <h2 id="not_found" style="color:black;">{{ $t("not_found") }}</h2>
            </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PageLoader from "@/components/PageLoader.vue";
// import HeaderComp1 from "@/components/HeaderComp1.vue";
//import InternalNavbar from "@/components/InternalNavbar.vue";
export default {
  components: {
    PageLoader,
    //HeaderComp1,
  //InternalNavbar
  },
  data: function() {
    return {
      loaded:true,
      result: { total: 0 }
    };
  },
  methods: {
    get_result: function(page = 1) {
      var vm = this;
      this.$http.get("partners?count=10&page=" + page).then(response => {
        vm.result = response.data;
      });
      this.$emit("updateHead");
      vm.loaded = false;
    }
  },
  created: function() {
    this.loading(7000);
    this.get_result();
  }
};
</script>
