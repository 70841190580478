<template>
  <div>
    <!-- <HeaderComp1 /> -->
    <section id="affiliates-intrnal-page" class="content-height">
      <!--<InternalNavbar />-->
      <PageLoader />
      <div class="hr"></div>
      <div class="container">
        <div class="titleS">
          <!-- <span>{{ this.$route.params.id }}</span> -->
          <span>{{ affilliate[$i18n.locale + "_title"] }}</span>
        </div>
        <div class="content">
          <div class="row justify-content-center">
            <div class="col-12">
              <p>{{ affilliate[$i18n.locale + "_desc"] }}</p>
            </div>
            <div
              class="col-md-2"
              v-for="(r, i) in affilliate.conditionable"
              :key="r.en_title + i"
            >
              <a
                :href="
                  'http://etf-admin.ispdemos.com/storage/app/public/' + r.img
                "
                target="_blank"
                download
              >
                <img
                  :title="r[$i18n.locale + '_title']"
                  class="img-fluid"
                  width="80px"
                  src="../../assets/images/pdf.png"
                  :alt="r[$i18n.locale + '_title']"
                />
                <span class="d-block">{{ r[$i18n.locale + "_title"] }}</span>
              </a>
              <!-- <router-link :to="this.$route.params.id + '/login'" tag="a">
                <img class="img-fluid" width="80px" src="../../assets/images/pdf.png" alt />
              </router-link>-->
            </div>
          </div>
          <!-- <transition name="slide-fade">
            <router-view></router-view>
          </transition>-->
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PageLoader from "@/components/PageLoader.vue";
// import HeaderComp1 from "@/components/HeaderComp1.vue";
//import InternalNavbar from "@/components/InternalNavbar.vue";
export default {
  components: {
    PageLoader,
    //HeaderComp1,
  //InternalNavbar
  },
  data: function() {
    return {
      
      affilliate: []
    };
  },
  methods: {},
  mounted() {
    
    this.$http
      .get("affilates/" + this.$route.params.id)
      .then(response => {
        this.affilliate = response.data.data;
        if (Object.keys(this.affilliate).length == 1) {
          this.$router.push("/404");
        }
      })
      .catch(error => console.log(error))
      .finally(() => (this.loading = false));
  }
};
</script>
<style scoped>
.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(10px);
  opacity: 0;
}
</style>
