<template>
  <div>
    <!-- <HeaderComp1 /> -->
    <section id="board-members-internal-page" class="content-height">
      <!--<InternalNavbar />-->
      <PageLoader />
      <div class="hr"></div>
      <div class="container">
        <div class="titleS">
          <span>{{ $t("title.etf_staff") }}</span>
        </div>
        <div class="content">
          <div class="row justify-content-center bootstrap">
            <template v-for="r in row.councils">
              <div class="col-md-3" v-for="(r1, i) in r.councils_member" :key="r1.title+i">
                <div class="card">
                  <img
                    :title="r1[$i18n.locale+'_title']"
                    class="img-fluid"
                    :src="'http://etf-admin.ispdemos.com/storage/app/public/' + r1.img"
                    :alt="r[$i18n.locale+'_title']"
                  />
                  <div class="card-info">
                    <li>{{r1[$i18n.locale+'_name']}}</li>
                    <li>{{r1[$i18n.locale+'_group_name']}}</li>
                  </div>
                </div>
              </div>
            </template>
          </div>
            <div v-if=" (row.councils && row.councils.length ? row.councils.length : 0) == 0">
                <div id="loading_c"></div>
              <h2 id="not_found" style="color:black;">{{ $t("not_found") }}</h2>
            </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PageLoader from "@/components/PageLoader.vue";
// import HeaderComp1 from "@/components/HeaderComp1.vue";
//import InternalNavbar from "@/components/InternalNavbar.vue";
export default {
  components: {
    PageLoader,
    //HeaderComp1,
  //InternalNavbar
  },
  data: function() {
    return {
      
      row: []
    };
  },
  methods: {},
  mounted() {
    
    this.$http
      .get("boards/ETF_Stuf")
      .then(response => {
        this.row = response.data.data;
      })
      .catch(error => console.log(error))
      .finally(() => (this.loading = false));
    this.loading(4000);
  }
};
</script>