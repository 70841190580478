<template>
  <div class="login-parent">
    <div class="login-card">
      <router-link :to="'/committes/'+this.$route.params.id" tag="a">
        <!-- <span @click="$router.go(-1)" class="close">X</span> -->
        <span class="close">X</span>
      </router-link>
      <!-- <form> -->
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <h2>{{ $t("welcome") }}</h2>
            <div class="form-group col-md-12">
              <label>•{{ $t("title.username") }}</label>
              <input
                type="username"
                class="form-control"
                id="email"
                name="username"
                value="m.raafat53"
                v-model.trim="$v.username.$model"
                :class="{
                    'is-invalid': $v.username.$error,
                    'is-valid': !$v.username.$invalid
                  }"
              />
              <div class="valid-feedback">
                <span>Username is correct</span>
              </div>
              <div class="invalid-feedback">
                <span v-if="!$v.username.required">• username is required.</span>
                <span v-if="!$v.username.minLength">
                  • username must have at least
                  {{ $v.username.$params.minLength.min }} Letters
                </span>
                <span v-if="!$v.username.maxLength">
                  • username must have at most
                  {{ $v.username.$params.maxLength.max }} Letters
                </span>
              </div>
            </div>
            <div class="form-group col-md-12">
              <label>•{{ $t("title.password") }}</label>
              <input
                type="password"
                class="form-control"
                id="password"
                name="password"
                v-model.trim="$v.password.$model"
                :class="{
                    'is-invalid': $v.password.$error,
                    'is-valid': !$v.password.$invalid
                  }"
              />
              <div class="valid-feedback">
                <span>password is correct</span>
              </div>
              <div class="invalid-feedback">
                <span v-if="!$v.password.required">• password is required.</span>
                <span v-if="!$v.password.minLength">
                  • password must have at least
                  {{ $v.password.$params.minLength.min }} Letters
                </span>
                <span v-if="!$v.password.maxLength">
                  • Name must have at most
                  {{ $v.password.$params.maxLength.max }} Letters
                </span>
              </div>
            </div>
          </div>
          <div class="text-center">
            <button
              type="submit"
              class="btn btn-default"
              :class="{'disable': this.count==5}"
              v-on:click="login()"
            >{{ $t("title.login") }}</button>
          </div>
        </div>
      </div>
      <!-- </form> -->
    </div>
  </div>
</template>

<script>
import { required, minLength, maxLength } from "vuelidate/lib/validators";
export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      login_row: [],
      count:0
    };
  },
  validations: {
    username: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(20)
    },
    password: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(40)
    }
  },
  mounted() {
    
    this.$http
      .get("commites/" + this.$route.params.id)
      .then(response => {
        this.login_row = response.data.data;
      })
      .catch(error => console.log(error))
      .finally(() => (this.loading = false));
  },
  methods: {
    login() {
      this.$v.$touch();
      if (
        this.username == this.login_row.username &&
        this.password == this.login_row.password
      ) {
        // window.location.href = 'http://etf-admin.ispdemos.com/storage/app/public/';  
        window.open("http://etf-admin.ispdemos.com/storage/app/public/"+this.$route.query.Encrypted_contact_information+".pdf", "_blank"); 
        this.$router.go(-1);
      }
      else{
        this.count++;
        this.$notify({
          group: "foo",
          type: "error",
          title: "Wrong message",
          text: "Username or password is not correct"
        });
      }
    }
  }
};
</script>
