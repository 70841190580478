<template>
  <div>
    <!-- <HeaderComp1 /> -->
    <section id="committes-intrnal-page" class="content-height">
      <!--<InternalNavbar />-->
      <PageLoader />
      <div class="hr"></div>
      <div class="container">
        <div class="titleS">
          <span>{{ row[$i18n.locale + "_title"] }}</span>
        </div>
        <div class="content">
          <div class="row">
            <p>{{ row[$i18n.locale + "_desc"] }}</p>
            <div
              class="col-md-2"
              v-for="(r, i) in row.conditionable"
              :key="r.en_title + i"
            >
              <!-- <a :href="'http://etf-admin.ispdemos.com/storage/app/public/' + r.img" target="_blank" download> -->
              <router-link
                :to="commet + '/login?Token='+makeid()+'&Encrypted_contact_information=' + r.img.replace('.pdf','') + '&Encrypted__information=' + makeid()"
                tag="a"
              >
                <img
                  :title="r[$i18n.locale + '_title']"
                  class="img-fluid"
                  width="80px"
                  src="../../assets/images/pdf.png"
                  :alt="r[$i18n.locale + '_title']"
                />
                <span class="d-block">{{ r[$i18n.locale + "_title"] }}</span>
              </router-link>
              <!-- </a> -->
            </div>
          </div>
          <transition name="slide-fade">
            <router-view></router-view>
          </transition>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PageLoader from "@/components/PageLoader.vue";
// import HeaderComp1 from "@/components/HeaderComp1.vue";
//import InternalNavbar from "@/components/InternalNavbar.vue";
export default {
  components: {
    PageLoader,
    //HeaderComp1,
  //InternalNavbar
  },
  data: function() {
    return {
      
      row: []
    };
  },
  methods: {
     makeid() {
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for (var i = 0; i < 100; i++)
          text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
      }
  },
  computed: {
    commet() {
      return this.$route.params.id;
    }
  },
  mounted() {
    
    this.$http
      .get("commites/" + this.$route.params.id)
      .then(response => {
        this.row = response.data.data;
        if (Object.keys(this.row).length == 1) {
          this.$router.push("/404");
        }
      })
      .catch(error => console.log(error))
      .finally(() => (this.loading = false));
  }
};
</script>
<style scoped>
.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(10px);
  opacity: 0;
}
</style>
