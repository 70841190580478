<template>
  <div>
    <!-- <HeaderComp1 /> -->
    <section id="doing-business-page" class="content-height">
      <!--<InternalNavbar />-->
      <PageLoader />
      <div class="hr"></div>
      <div class="container-fluid">
        <div class="titleS">
          <span>{{ $t("title.doing_business") }}</span>
        </div>
        <div class="content text-right" dir="rtl">
          <!-- Nav tabs -->
          <ul class="nav nav-tabs custom" role="tablist">
            <li class="nav-item"><a>{{$t("search_results")}}</a></li>
          </ul>
          <div class="tab-content">
            <div v-if="(result.data && result.data.length ? result.data.length : 0) == 0"><h2>لا يوجد نتائج</h2></div>
            <div v-for="(r2,i) in result.data" :key="r2.en_slug+i">
              <h3>{{r2.ar_title}}</h3>
              <p>
                {{getPragraphLimit(r2.ar_desc , 180)}}
                <a
                  href="#"
                  data-toggle="modal"
                  :data-target="'#exampleModal'+ i"
                >المزيد</a>
              </p>
              <div
                class="modal fade"
                :id="'exampleModal'+ i"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">{{r2.ar_title}}</h5>
                    </div>
                    <div class="modal-body">{{r2.ar_desc}}</div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" data-dismiss="modal">إغلاق</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PageLoader from "@/components/PageLoader.vue";
// import HeaderComp1 from "@/components/HeaderComp1.vue";
//import InternalNavbar from "@/components/InternalNavbar.vue";
export default {
  components: {
    PageLoader,
    //HeaderComp1,
  //InternalNavbar
  },
  data: function() {
    return {
      result: { total: 0 }
    };
  },
    computed: {
    getFullPath() {
      return this.$route.query;
    }
  },
  watch: {
    getFullPath() {
      this.get_result();
    }
  },
  methods: {
    get_result: function() {
      var vm = this;
      this.$http
        .get("search?input=" + this.$route.query.input)
        .then(response => {
          vm.result = response.data;
        });
      this.$emit("updateHead");
      this.loaded = false;
    }
  },
  created: function() {
    this.get_result();
  }
};
</script>
