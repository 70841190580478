<template>
  <div>
    <!-- <HeaderComp1 /> -->
    <section id="partners-internal" class="content-height">
      <!--<InternalNavbar />-->
      <PageLoader />
      <div class="hr"></div>
      <div class="container">
        <div class="titleS">
          <!-- <span>{{ this.$route.params.id }}</span> -->
          <span>{{ partner[$i18n.locale + "_title"] }}</span>
        </div>
        <div class="content">
          <img
            :title="partner[$i18n.locale + '_title']"
            class="img-fluid"
            :src="
              'http://etf-admin.ispdemos.com/storage/app/public/' + partner.img
            "
            :alt="partner[$i18n.locale + '_title']"
          />
          <p>{{ partner[$i18n.locale + "_desc"] }}</p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PageLoader from "@/components/PageLoader.vue";
// import HeaderComp1 from "@/components/HeaderComp1.vue";
//import InternalNavbar from "@/components/InternalNavbar.vue";
export default {
  components: {
    PageLoader,
    //HeaderComp1,
  //InternalNavbar
  },
  data: function() {
    return {
      
      partner: []
    };
  },
  methods: {},
  mounted() {
    
    this.$http
      .get("partners/" + this.$route.params.id)
      .then(response => {
        this.partner = response.data.data;
        response => (this.partner = response.data.data);
        if (Object.keys(this.partner).length == 0) {
          this.$router.push("/404");
        }
      })
      .catch(error => console.log(error))
      .finally(() => (this.loading = false));
  }
};
</script>
