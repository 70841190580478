<template>
  <div>
    <!-- <HeaderComp1 /> -->
    <section id="board-members-page" class="content-height">
      <!--<InternalNavbar />-->
      <PageLoader />
      <div class="hr"></div>
      <div class="container">
        <div class="titleS">
          <span>{{ $t("title.board_members") }}</span>
        </div>
        <div class="content">
          <div class="row justify-content-center bootstrap">
            <div
              class="col-md-6 col-lg-3"
              v-for="(r, i) in result.data"
              :key="r.en_title+i"
              v-show="r.id!=18"
            >
              <div class="card">
                <!-- <router-link :to="'/board-members-management/board-members/' + r.en_slug" tag="a"> -->
                <!-- <img
                  :title="r[$i18n.locale+'_title']"
                  class="img-fluid"
                  :src="'http://etf-admin.ispdemos.com/storage/app/public/' + r.img"
                  :alt="r[$i18n.locale+'_title']"
                /> -->
                <div class="img" :style="{ backgroundImage: ` url(http://etf-admin.ispdemos.com/storage/app/public/${r.img})` }">

                </div>
                <!-- </router-link> -->
                <span class="date">{{r.date}}</span>
                <router-link
                  :to="'/board-members-management/board-members/' + r.en_slug"
                  tag="a"
                >{{r[$i18n.locale+'_title']}}</router-link>
              </div>
            </div>
          </div>
          <nav aria-label="Page navigation example">
            <pagination :align="'center'" :data="result" @pagination-change-page="get_result">
              <span slot="prev-nav" v-html="$t('pagination.previous')"></span>
              <span slot="next-nav" v-html="$t('pagination.next')"></span>
            </pagination>
          </nav>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PageLoader from "@/components/PageLoader.vue";
// import HeaderComp1 from "@/components/HeaderComp1.vue";
//import InternalNavbar from "@/components/InternalNavbar.vue";
export default {
  components: {
    PageLoader,
    //HeaderComp1,
  //InternalNavbar
  },
  data: function() {
    return {
      result: { total: 0 }
    };
  },
  methods: {
    get_result: function(page = 1) {
      var vm = this;
      this.$http.get("boardCouncils/1?count=11&page=" + page).then(response => {
        vm.result = response.data;
      });
      this.$emit("updateHead");
      this.loaded = false;
    }
  },
  mounted: function() {
    // this.get_result();
  },
  created: function() {
    this.get_result();
  }
};
</script>